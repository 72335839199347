const supportedOps = ["avg", "count", "max", "min", "sum"];
const supportedMathOps = ["/", "^", "*", "+", "-"];
const conditionalRegex = /^if\s*\(([`\w_.\s=<>()+\-*/^]+),\s*([`\w.,:_\s+\-*/^"]+|(sum|avg|min|max|count)\(.*?\)|".*?"),\s*([`\w.,:_\s()+\-*/^"]+|".*?")\)$/i;
const conditionStmtRegex = /([`\w._()+\-*/\s]+)\s*([<>=]+)\s*([`\w._()+\-*/\s]+)/i;

const hasBackticks = (formula) => formula.includes("`");

// Parse arguments enclosed in backticks
const parseFormulaBacktickArgs = (formula) => {
    const args = [];
    let inBacktickEnclosure = false;
    let currentToken = "";
    let currentNameToken = "";
    let parsedRowId = "";
    let parsedColName = "";

    for (const ch of formula) {
        currentToken += ch;

        if (ch === "`") {
        inBacktickEnclosure = !inBacktickEnclosure;

        if (!inBacktickEnclosure && currentToken.endsWith("`")) {
            parsedColName = currentNameToken;
            args.push(`\`${parsedRowId}\`.\`${parsedColName}\``);
            parsedRowId = "";
            parsedColName = "";
        }
        continue;
        }

        if (inBacktickEnclosure) {
        currentNameToken += ch;
        } else if (ch === ".") {
        parsedRowId = currentNameToken;
        currentNameToken = "";
        currentToken = "";
        } else if ([" ", "(", ")"].includes(ch) || supportedMathOps.includes(ch)) {
        if (parsedRowId && currentNameToken) {
            parsedColName = currentNameToken;
            args.push(`\`${parsedRowId}\`.\`${parsedColName}\``);
            parsedRowId = "";
            parsedColName = "";
        }
        currentNameToken = "";
        currentToken = "";
        }
    }

    return args;
};

// Evaluate a condition based on the operator
const evaluateCondition = (lhs, op, rhs) => {
    switch (op) {
        case ">": return lhs > rhs;
        case "<": return lhs < rhs;
        case ">=": return lhs >= rhs;
        case "<=": return lhs <= rhs;
        case "=":
        case "==": return lhs === rhs;
        case "<>": return lhs !== rhs;
        default: return false;
    }
};

// Process conditional formulas
const processConditionalFormula = (condition, formulaIfTrue, formulaIfFalse, currentRowId = "") => {
    const match = condition.match(conditionStmtRegex);
    if (!match) return { value: "#NAME?", args: [], mappedArgs: {} };

    // eslint-disable-next-line no-unused-vars
    const [_, lhs, op, rhs] = match;
    const lhsParsed = parseFormulaForCalculation(`=${lhs}`, currentRowId);
    const rhsParsed = parseFormulaForCalculation(`=${rhs}`, currentRowId);

    const lhsValue = calculateFormula(lhsParsed);
    const rhsValue = calculateFormula(rhsParsed);

    const conditionMet = evaluateCondition(lhsValue, op, rhsValue);
    const resultFormula = conditionMet ? formulaIfTrue : formulaIfFalse;

    return parseFormulaForCalculation(`=${resultFormula}`, currentRowId);
};

const calculateFormula = () => {
    // noop, because we only calculate actual values in the backend
    return "";
};

// Parse formula for calculation
export const parseFormulaForCalculation = (formula, currentRowId = "") => {
    if (!formula.startsWith("=")) {
      return { formula, value: formula, args: [], mappedArgs: {} };
    }
  
    let actualFormula = formula.trim();
    formula = actualFormula.slice(1); // Remove the "=" sign
  
    if (currentRowId) {
      const itemRegex = /`\bitem\b`/gi;
      formula = formula.replace(itemRegex, `\`${currentRowId}\``);
      formula = formula.replace(/\bitem\b/gi, currentRowId);
    }
  
    const isConditional = conditionalRegex.test(formula);
    const isSupportedAggOp = supportedOps.some(op => formula.toLowerCase().startsWith(op))
      && !supportedMathOps.some(mathOp => formula.includes(mathOp));
  
    if (isConditional) {
      const match = formula.match(conditionalRegex);
      if (!match) return { value: "#NAME?", args: [], mappedArgs: {} };
  
      // eslint-disable-next-line no-unused-vars
      const [_, condition, formulaIfTrue, __, formulaIfFalse] = match;
      return processConditionalFormula(condition, formulaIfTrue, formulaIfFalse, currentRowId);
    }
  
    if (isSupportedAggOp) {
      const opsStr = supportedOps.join("|");
      const match = formula.match(new RegExp("(" + opsStr + ")\\(([\\w.,:_\\s`]+)\\)", "i"));
      if (!match) return { value: "#NAME?", args: [], mappedArgs: {} };
  
      const op = match[1];
      const formulaArgs = match[2].replace(/\s/g, "").split(",");
      const args = [];
      const mappedArgs = {};
  
      for (const arg of formulaArgs) {
        if (isNaN(arg)) {
          mappedArgs[arg] = null; // Assume row.column reference
        } else {
          args.push(arg);
        }
      }
  
      return { formula: actualFormula, args, mappedArgs, op: op.toLowerCase() };
    }
  
    const isNumericOrQuotedString = !isNaN(formula) || (formula.startsWith('"') && formula.endsWith('"'));
    if (isNumericOrQuotedString) {
      return { value: formula, args: [], mappedArgs: {} };
    }
  
    if (supportedMathOps.some(op => formula.includes(op))) {
      const args = hasBackticks(formula) 
        ? parseFormulaBacktickArgs(formula) 
        : formula.split(new RegExp(supportedMathOps.map(op => `\\${op}`).join("|")));
  
      const mappedArgs = {};
      for (const arg of args) {
        if (isNaN(arg.trim())) {
          mappedArgs[arg.trim()] = null;
        }
      }
  
      return { formula: actualFormula, args, mappedArgs, op: "math_expr" };
    }
  
    return { formula: actualFormula, args: [formula], mappedArgs: { [formula]: null } };
};
