import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import {
  Button,
  Confirm,
  Message,
  Modal,
  Header,
  Grid,
  GridColumn,
} from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { useTable, useSortBy, useFilters, usePagination, useGlobalFilter } from "react-table";

import DataExportButton from "./DataExportButton/DataExportButton";
import ProgressIndicator from "./ProgressIndicator/ProgressIndicator";

import {
  getLayouts,
  saveLayout,
  selectLayout,
  deleteLayout,
  editLayout,
  setSelectedRow,
  commit,
  undoCommit,
  updateTableCellsAndSync,
  updateTableRow,
  updateTableCellByRowAndColumn,
} from "../../actions/ordersTable";

import { setCurrentPage, setPageSize } from "../../actions/items";

import { useMemoCompare } from "../../utils/hooks";

import { itemAccessors } from "../../utils/constants";

import {
  mapAccessorsToColumns,
  getSuggestedOrderColumn,
  mapItemsToRecordsObject,
  getSortedColumns,
} from "../../utils/mappers/ordersTable";

import { getSafeSelectedWorkspace } from "../../utils/mappers/user";

import { resolveEditPermission } from "../../utils/mappers/workspace";

import { equalsAny, multipleOptions } from "../../utils/filters";

import Table from "./Table/Table";
import CustomLayoutModal from "./CustomLayoutModal/CustomLayoutModal";
import SuggestedOrderFilter from "./Filters/SuggestedOrderFilter";

import ActualOrderCell from "./EditableCells/ActualOrderCell";
import VarianceCell from "./EditableCells/VarianceCell";

import "./OrdersTable.css";
import PageSelector from "./PageSelector/PageSelector";
import SearchBar from "./SearchBar/SearchBar";
import OrdersErrorDropdown from "./OrderErrors/OrderErrors";
import OrderColumns from "./Ordercolumns/OrderColumns";
import DynamicFieldsButton from "./DynamicFieldsButton/DynamicFieldsButton";
import DynamicFieldsModal from "./DynamicFieldsModal/DynamicFieldsModal";
import {
  doLoadMetadata,
  doSaveDynamicField,
  doSingleCalcForTableCell,
  doUpdateDynamicField
} from "../../actions/dynamicFields";
import { parseFormulaForCalculation } from "../../utils/dynamicFormulaParser";

export const OrdersTable = ({
  items,
  getLayouts,
  currentLayout,
  savedLayouts,
  saveLayout,
  selectLayout,
  deleteLayout,
  editLayout,
  setSelectedRow,
  isCommitted,
  commit,
  undoCommit,
  updateTableCellsAndSync,
  updateTableRow,
  ordersCalculationId,
  currentWorkspaceId,
  canEditCurrentWorkspace,
  loadingUpdate,
  pageIndex,
  pageSize,
  setCurrentPage,
  setPageSize,
  datasetColumns,
  hasBatchData,
  selectedRow,
  orderErrors,
  notificationFilterArray,
  selectedActualOrderCell,
  orderTableContainerWidth,
  lastActualOrderInputValue,
  draftDynamicField,
  dynamicFieldCrudOperationError,
  dynamicFieldLoadingCrudOperation,
  dynamicFieldNamesTitlesMap,
  dynamicFieldsWithActualOrder,
  singleCalcTableCellResults,
  doLoadMetadata,
  doSaveDynamicField,
  doSingleCalcForTableCell,
  doUpdateDynamicField,
  updateTableCellByRowAndColumn,
}) => {  
  const { t } = useTranslation();
  
  const [currentSingleCalcRowIndex, setCurrentSingleCalcRowIndex] = useState(-1);

  useEffect(() => {
    if (!_.isNil(currentWorkspaceId)) {
      getLayouts(currentWorkspaceId);
    }
  }, [getLayouts, currentWorkspaceId]);

  useEffect(() => {
    doLoadMetadata(currentWorkspaceId);
  }, [currentWorkspaceId, doLoadMetadata]);

  //This function update order of columns
  const updateTableData = (
    rowIndex,
    columnId,
    value,
    performRequest = false
  ) => {
    if (performRequest) {
      updateTableRow(items[rowIndex], columnId, value);
      updateTableCellsAndSync(
        ordersCalculationId,
        currentWorkspaceId,
        items[rowIndex][itemAccessors.code],
        items[rowIndex][itemAccessors.location],
        columnId === itemAccessors.actualOrder
          ? value
          : items[rowIndex][itemAccessors.actualOrder],
        columnId === itemAccessors.orderVariance
          ? value
          : items[rowIndex][itemAccessors.orderVariance]
      );

      if (columnId === itemAccessors.actualOrder) {
        checkAndUpdateActualOrderDynamicFields(rowIndex)
      }
    } else {
      updateTableRow(items[rowIndex], columnId, value);
    }
  };

  const checkAndUpdateActualOrderDynamicFields = (rowIndex) => {
    // early return if there are no dynamic fields with a formula that contain actual_order
    const columnIds = Object.keys(dynamicFieldsWithActualOrder);
    if (columnIds.length === 0) {
      return;
    }
    
    if (!items[rowIndex]) {
      return;
    }
    const item = items[rowIndex];

    const rowId = (item.material_location + '_' + item.material_code).replaceAll(/\s+/g, '_');
    setCurrentSingleCalcRowIndex(rowIndex);
    _.forEach(columnIds, columnId => {
      const formula = dynamicFieldsWithActualOrder[columnId];
      const substituteArgValues = [];
      if (formula.indexOf("actual_order") > -1) {
        // only parse for substitution if the formula actually contains an actual_order reference
        const parsed = parseFormulaForCalculation(formula);
        const mappedArgs = parsed.mappedArgs;
        const namedArgs = Object.keys(mappedArgs);
        for (const namedArg of namedArgs) {
          const lcNamedArg = namedArg;
          if (/`?item`?\.?`?actual_order`?/gi.test(lcNamedArg)) {
            let substValue = {};
            substValue[namedArg] = lastActualOrderInputValue;
            substituteArgValues.push(substValue);
          }
        }
      }

      doSingleCalcForTableCell(currentWorkspaceId, formula, rowId, rowIndex, columnId, substituteArgValues);
    });
  }

  // eslint-disable-next-line
  const itemForCurrentSingleCalcRowIndex = useMemo(() => items[currentSingleCalcRowIndex], [currentSingleCalcRowIndex]);

  const updateTableCellForSingleCalc = useCallback((columnId, value, actualOrderValue) => {
    updateTableCellByRowAndColumn(itemForCurrentSingleCalcRowIndex, columnId, value, actualOrderValue);
  }, [updateTableCellByRowAndColumn, itemForCurrentSingleCalcRowIndex]);

  useEffect(() => {
    if (currentSingleCalcRowIndex === -1 || !singleCalcTableCellResults[currentSingleCalcRowIndex]) {
      return;
    }

    const calcResults = singleCalcTableCellResults[currentSingleCalcRowIndex];
    _.forEach(Object.keys(calcResults), columnId => {
      const value = calcResults[columnId];
      if (value.trim().length > 0) {
        const newActualOrderValue = parseInt(lastActualOrderInputValue, 10);
        updateTableCellForSingleCalc(columnId, value, isNaN(newActualOrderValue) ? 0 : newActualOrderValue);
      }
    });
  }, [
    currentSingleCalcRowIndex,
    singleCalcTableCellResults,
    lastActualOrderInputValue,
    updateTableCellByRowAndColumn,
    updateTableCellForSingleCalc
  ]);

  const columns = useMemo(() => {
    const unsortedColumns = mapAccessorsToColumns(
      t,
      Object.keys(items[0]),
      ActualOrderCell,
      VarianceCell,
      datasetColumns,
      dynamicFieldNamesTitlesMap,
    );
    
    return getSortedColumns(unsortedColumns, hasBatchData);
  }, [t, items, datasetColumns, hasBatchData, dynamicFieldNamesTitlesMap]);

  const memoizedfilterTypes = useMemo(() => {
    return {
      equalsAny,
      multipleOptions,
    };
  }, []);

  const [editingLayout, setEditingLayout] = useState(false);

  const [showCommitConfirm, setShowCommitConfirm] = useState(false);
  const [showNewLayoutModal, setShowNewLayoutModal] = useState(false);
  const [showDeleteLayoutConfirm, setShowDeleteLayoutConfirm] = useState(false);
  const [showOverwriteLayoutConfirm, setShowOverwriteLayoutConfirm] = useState(false);
  const [columnsConfig, setColumnsConfig] = useState({})
  const [showDynamicFieldsEditor, setShowDynamicFieldsEditor] = useState(false);

  const newData = useMemo(()=>{
    const newItems = items
                      .map((item)=>({...item, 
                        buffer_zone_color: item.buffer_zone_color 
                              ? t("ordersTable.columns."+item.buffer_zone_color)
                              : null}))
    return newItems
  }, [t, items])


  // useTable Hook to send the columns and data to build the table
  const tableInstance = useTable(
    {
      debug: true,
      columns,
      data: newData,
      initialState: {
        ...currentLayout.initialState,
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
      stateReducer: (newState, action) => {
        if (action.type === "changeLayout") {
          const selectedLayout = savedLayouts.find(
            (layout) => layout.id === action.payload
          );
          return {
            ...newState,
            hiddenColumns: _.isNil(selectedLayout)
              ? []
              : selectedLayout.initialState.hiddenColumns,
          };
        }
        return newState;
      },
      filterTypes: memoizedfilterTypes,
      updateTableData,
      // Some unexpected updates of table can be controlled with use table properties. Check this answer:
      // https://stackoverflow.com/questions/66656018/prevent-re-sort-on-data-change-or-while-row-is-being-edited 
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { allColumns, dispatch, setHiddenColumns } = tableInstance;  

  // To be called when the layout is edited, i.e. a column is toggled
  const onColumnToggle = () => {
    setEditingLayout(true);
  };

  const onLayoutSelected = (id) => {
    setEditingLayout(false);
    dispatch({ type: "changeLayout", payload: id });
    selectLayout(id);
  };

  const onSaveLayout = (name) => {
    saveLayout(currentWorkspaceId, {
      name: name,
      initialState: tableInstance.state,
    });
    setEditingLayout(false);
  };

  const isDuplicateName = (name) => {
    return _.includes(
      savedLayouts.map((layout) => layout.name),
      name
    );
  };

  const shouldDisableCommit = () => {
    return isCommitted || loadingUpdate;
  };

  const handleClickSaveDynamicField = () => {
    if (draftDynamicField.dynamic_field_id > 0) {
      doUpdateDynamicField(currentWorkspaceId);
      return;
    }
    doSaveDynamicField(currentWorkspaceId);
  }

  useEffect(() => {
    if (!dynamicFieldLoadingCrudOperation) {
      if (dynamicFieldCrudOperationError) {
        // early return to keep the modal open if an error occurred
        return;
      }

      setShowDynamicFieldsEditor(false);
    }
  }, [dynamicFieldCrudOperationError, dynamicFieldLoadingCrudOperation, setShowDynamicFieldsEditor]);

  const memoizedPrepareRow = useMemoCompare(
    {
      prepareRow: tableInstance.prepareRow,
      tableState: tableInstance.state,
      ordersCalculationId: ordersCalculationId,
      hasBatchData: hasBatchData,
    },
    (prev) => {
      return (
        prev &&
        _.isEqual(prev.tableState, tableInstance.state) &&
        _.isEqual(prev.ordersCalculationId, ordersCalculationId) &&
        _.isEqual(prev.hasBatchData, hasBatchData)
      );
    }
  );

  const memoizedTable = useMemo(
    () => { 
      return (
        <Table
          setFilter={tableInstance.setFilter}
          getTableProps={tableInstance.getTableProps}
          getTableBodyProps={tableInstance.getTableBodyProps}
          headerGroups={tableInstance.headerGroups} 
          filterRows={tableInstance.filteredRows}          
          rows={tableInstance.page}
          allRows={tableInstance.rows}
          prepareRow={memoizedPrepareRow.prepareRow}
          ordersCalculationId={memoizedPrepareRow.ordersCalculationId}
          onSelectRow={setSelectedRow}
          editable={canEditCurrentWorkspace && !isCommitted}
          tableState={tableInstance.state}
          selectedRow={selectedRow}   
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // Ignoring exhaustive deps warnings because changes in tableInstance
      // are already accounted for in memoizedPrepareRow
      ordersCalculationId,
      tableInstance.rows,
      memoizedPrepareRow,
      setSelectedRow,
      selectedRow,
      isCommitted,
      canEditCurrentWorkspace,
    ]
  );

  //Notification panel filter
  //watch changes of notificationFilterArray in OnHandStockSummary component 
  useEffect(()=>{
    if(Array.isArray(notificationFilterArray)){
      tableInstance.setFilter("location_code_concatenation", notificationFilterArray)
      return
    }
    tableInstance.setAllFilters([])
  }, [tableInstance, notificationFilterArray])

  // Horizontal scroll when user select row to show order cell in screen
  useEffect(() => {
    if(selectedActualOrderCell){
      document.getElementById("container-data-table").scrollLeft += orderTableContainerWidth;
    }
  }, [ selectedActualOrderCell, orderTableContainerWidth ])


  return (
    <div>     
      <div className="orders-table">  

        {/* Modal to give a name to configuration */}
        {showNewLayoutModal && (
          <CustomLayoutModal
            onSubmit={onSaveLayout}
            onCancel={() => setShowNewLayoutModal(false)}
            checkDuplicate={isDuplicateName}
          />
        )}
        
        {/* Confirm messages when you ara making changes */}
        <Confirm
          open={showDeleteLayoutConfirm}
          content={t("ordersTable.layouts.deleteConfirmMessage", {
            layout: currentLayout.name,
          })}
          cancelButton={t("cancel")}
          confirmButton={t("confirm")}
          onCancel={() => setShowDeleteLayoutConfirm(false)}
          onConfirm={() => {
            deleteLayout(currentWorkspaceId, currentLayout.id);
            setShowDeleteLayoutConfirm(false);
          }}
        />
        <Confirm
          open={showOverwriteLayoutConfirm}
          content={t("ordersTable.layouts.overwriteConfirmMessage", {
            layout: currentLayout.name,
          })}
          cancelButton={t("cancel")}
          confirmButton={t("confirm")}
          onCancel={() => setShowOverwriteLayoutConfirm(false)}
          onConfirm={() => {
            // Here have to change setHiddenColumns because table instance doesn't catch the last change when click at change button
            setHiddenColumns(columnsConfig.hiddenColumns)
            editLayout(currentWorkspaceId, columnsConfig, currentLayout);
            setShowOverwriteLayoutConfirm(false);
          }}
        />


        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column
              stretched
              width={11}
              verticalAlign="middle"
              >
              <Grid.Row className="order-table-interactive-buttons">
                  <DataExportButton 
                    tableInstance={tableInstance} />

                  <SuggestedOrderFilter 
                    column={getSuggestedOrderColumn(allColumns)}/>

                  <OrderColumns
                        currentLayout={currentLayout}
                        savedLayouts={savedLayouts}
                        allColumns={allColumns}
                        onLayoutSelected={onLayoutSelected} 
                        setShowOverwriteLayoutConfirm={setShowOverwriteLayoutConfirm}
                        tableInstance={tableInstance}
                        setColumnsConfig={setColumnsConfig}
                        setShowDeleteLayoutConfirm={setShowDeleteLayoutConfirm}
                        onColumnToggle={onColumnToggle}
                        setShowNewLayoutModal={setShowNewLayoutModal}
                        editingLayout={editingLayout}
                    />

                <Grid.Column
                  width={5}
                  className="search-bar-input-column"
                  >
                  <SearchBar
                    setFilter={tableInstance.setGlobalFilter}
                    rawColumns={columns}
                    headerGroups={tableInstance.headerGroups}
                  />
                </Grid.Column>

                <Grid.Column
                  floated="left">
                  <Modal
                    open={showDynamicFieldsEditor}
                    trigger={
                      <DynamicFieldsButton
                        onClick={() => setShowDynamicFieldsEditor(true)}
                      />
                    }
                    onCancel={() => setShowDynamicFieldsEditor(false)}
                    content={<DynamicFieldsModal />}
                    actions={[
                      {
                        primary: true,
                        key: "save",
                        content: t("save"),
                        loading: dynamicFieldLoadingCrudOperation,
                        disabled: dynamicFieldLoadingCrudOperation,
                        onClick: () => {
                          handleClickSaveDynamicField();
                        }
                      },
                      {
                        key: "cancel",
                        content: t("close"),
                        disabled: dynamicFieldLoadingCrudOperation,
                        onClick: () => setShowDynamicFieldsEditor(false),
                      },
                    ]} />
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>

            <GridColumn
              verticalAlign="middle"
              width={5}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <ProgressIndicator />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span className="commit-undo-bar">
                      {isCommitted ? (
                        <Modal
                          open={showCommitConfirm}
                          trigger={
                            <Button
                              secondary
                              disabled={!canEditCurrentWorkspace}
                              onClick={() => setShowCommitConfirm(true)}
                            >
                              {t("ordersTable.commitUndoButton")}
                            </Button>
                          }
                          onCancel={() => setShowCommitConfirm(false)}
                          content={
                            <Modal.Content>
                              <Header>{t("ordersTable.undoConfirmationMessage")}</Header>
                              <Message warning>
                                {t("ordersTable.pendingChangesWarning")}
                              </Message>
                            </Modal.Content>
                          }
                          actions={[
                            {
                              key: "cancel",
                              content: t("cancel"),
                              onClick: () => setShowCommitConfirm(false),
                            },
                            {
                              key: "confirm",
                              content: t("confirm"),
                              primary: true,
                              onClick: () => {
                                if (!canEditCurrentWorkspace) {
                                  return;
                                }
                                undoCommit(ordersCalculationId, currentWorkspaceId);
                                setShowCommitConfirm(false);
                              },
                            },
                          ]}
                        ></Modal>
                      ) : (
                          <Confirm
                            open={showCommitConfirm}
                            trigger={
                              <div >
                                  <Button
                                    positive
                                    disabled={!canEditCurrentWorkspace || shouldDisableCommit()}
                                    onClick={() => {
                                      if (!canEditCurrentWorkspace) {
                                        return;
                                      }
                                      setShowCommitConfirm(true);
                                    }}
                                  >
                                    {t("ordersTable.commitButton")}
                                  </Button>

                              </div>
                            }
                            onConfirm={() => {
                              if (!canEditCurrentWorkspace) {
                                return;
                              }
                              commit(
                                ordersCalculationId,
                                currentWorkspaceId,
                                mapItemsToRecordsObject(items)
                              );
                              setShowCommitConfirm(false);
                            }}
                            onCancel={() => setShowCommitConfirm(false)}
                            content={t("ordersTable.commitConfirmationMessage")}
                            cancelButton={t("cancel")}
                            confirmButton={t("confirm")}
                          ></Confirm>
                        )}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </GridColumn>
          </Grid.Row>
          {
            !_.isEmpty(orderErrors) 
                        &&  <Grid.Row className="row-errors-order">
                              <Grid.Column
                                floated='right'
                                with={8}
                                className="errors-order"
                                >
                                  <OrdersErrorDropdown
                                    items={items}
                                    />
                              </Grid.Column>
                            </Grid.Row>
            }
        </Grid>



        {!_.isNil(ordersCalculationId) && memoizedTable}

        {items && items.length > pageSize && (
          <PageSelector
            canPreviousPage={tableInstance.canPreviousPage}
            canNextPage={tableInstance.canNextPage}
            pageOptions={tableInstance.pageOptions}
            pageCount={tableInstance.pageCount}
            gotoPage={(value) => {
              setCurrentPage(value);
              tableInstance.gotoPage(value);
            }}
            nextPage={() => {
              setCurrentPage(pageIndex + 1);
              tableInstance.nextPage();
            }}
            previousPage={() => {
              setCurrentPage(pageIndex - 1);
              tableInstance.previousPage();
            }}
            setPageSize={(value) => {
              setPageSize(Number(value));
              tableInstance.setPageSize(Number(value));
            }}
          />
        )}
      </div>
    </div>
  );
};

OrdersTable.propTypes = {
  items: PropTypes.array.isRequired,
  getLayouts: PropTypes.func,
  currentLayout: PropTypes.object,
  savedLayouts: PropTypes.arrayOf(PropTypes.object),
  saveLayout: PropTypes.func,
  selectLayout: PropTypes.func,
  deleteLayout: PropTypes.func,
  editLayout: PropTypes.func,
  setSelectedRow: PropTypes.func,
  isCommitted: PropTypes.bool,
  commit: PropTypes.func,
  undoCommit: PropTypes.func,
  updateTableCellsAndSync: PropTypes.func,
  updateTableRow: PropTypes.func,
  ordersCalculationId: PropTypes.number,
  currentWorkspaceId: PropTypes.number,
  canEditCurrentWorkspace: PropTypes.bool,
  loadingUpdate: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setPageSize: PropTypes.func,
  datasetColumns: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  hasBatchData: PropTypes.bool,
  selectedRow: PropTypes.object,
  orderErrors: PropTypes.array,
  notificationFilterArray: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  selectedActualOrderCell: PropTypes.bool,
  orderTableContainerWidth: PropTypes.number,
  lastActualOrderInputValue: PropTypes.string,
  dynamicFieldCrudOperationError: PropTypes.bool,
  dynamicFieldLoadingCrudOperation: PropTypes.bool,
  dynamicFieldNamesTitlesMap: PropTypes.object,
  dynamicFieldsWithActualOrder: PropTypes.object,
  draftDynamicField: PropTypes.object,
  singleCalcTableCellResults: PropTypes.object,
  doLoadMetadata: PropTypes.func,
  doSaveDynamicField: PropTypes.func,
  doSingleCalcForTableCell: PropTypes.func,
  doUpdateDynamicField: PropTypes.func,
  updateTableCellByRowAndColumn: PropTypes.func,
};

OrdersTable.defaultProps = {
  getLayouts: _.noop,
  currentLayout: {},
  savedLayouts: [],
  saveLayout: _.noop,
  selectLayout: _.noop,
  deleteLayout: _.noop,
  editLayout: _.noop,
  setSelectedRow: _.noop,
  isCommitted: false,
  commit: _.noop,
  undoCommit: _.noop,
  updateTableCellsAndSync: _.noop,
  updateTableRow: _.noop,
  ordersCalculationId: 0,
  currentWorkspaceId: 0,
  loadingUpdate: false,
  pageIndex: 0,
  pageSize: 10,
  setCurrentPage: _.noop,
  setPageSize: _.noop,
  hasBatchData: false,
  canEditCurrentWorkspace: false,
  orderErrors: [],
  draftDynamicField: {},
  dynamicFieldCrudOperationError: false,
  dynamicFieldLoadingCrudOperation: false,
  dynamicFieldNamesTitlesMap: {},
  dynamicFieldsWithActualOrder: {},
  singleCalcTableCellResults: {},
  doLoadMetadata: _.noop,
  doSaveDynamicField: _.noop,
  doSingleCalcForTableCell: _.noop,
  doUpdateDynamicField: _.noop,
  updateTableCellByRowAndColumn: _.noop,
};

const mapStateToProps = (state) => {
  return {
    currentLayout: state.ordersTable.currentLayout,
    savedLayouts: state.ordersTable.savedLayouts,
    isCommitted: state.ordersTable.isCommitted,
    ordersCalculationId: state.ordersTable.ordersCalculationId,
    currentWorkspaceId: state.workspace.currentWorkspace,
    canEditCurrentWorkspace:
      state.auth.isConnected &&
      resolveEditPermission(state.auth.user, getSafeSelectedWorkspace(state)),
    items: state.items.items,
    loadingUpdate: state.ordersTable.loadingUpdate,
    pageIndex: state.items.pageIndex,
    pageSize: state.items.pageSize,
    datasetColumns: state.dataset.columns,
    hasBatchData: !_.isNil(state.items.batchData),
    selectedRow: state.ordersTable.selectedRow,
    orderErrors: state.ordersTable.orderErrors,
    notificationFilterArray: state.ordersTable.notificationFilterArray,
    selectedActualOrderCell: state.ordersTable.selectedActualOrderCell,
    orderTableContainerWidth: state.ordersTable.orderTableContainerWidth,
    lastActualOrderInputValue: state.ordersTable.lastActualOrderInputValue,
    draftDynamicField: state.dynamicFields.draftDynamicField,
    dynamicFieldCrudOperationError: state.dynamicFields.crudOperationError,
    dynamicFieldLoadingCrudOperation: state.dynamicFields.loadingCrudOperation,
    dynamicFieldNamesTitlesMap: state.dynamicFields.dynamicFieldNamesTitlesMap,
    dynamicFieldsWithActualOrder: state.dynamicFields.dynamicFieldsWithActualOrder,
    singleCalcTableCellResults: state.dynamicFields.singleCalcTableCellResults,
    doLoadMetadata: state.dynamicFields.doLoadMetadata,
    doSaveDynamicField: state.dynamicFields.doSaveDynamicField,
    doSingleCalcForTableCell: state.dynamicFields.doSingleCalcForTableCell,
    doUpdateDynamicField: state.dynamicFields.doUpdateDynamicField,
    updateTableCellByRowAndColumn: state.ordersTable.updateTableCellByRowAndColumn,
  };
};

export default connect(mapStateToProps, {
  getLayouts,
  saveLayout,
  selectLayout,
  deleteLayout,
  editLayout,
  setSelectedRow,
  commit,
  undoCommit,
  updateTableCellsAndSync,
  updateTableRow,
  setCurrentPage,
  setPageSize,
  doLoadMetadata,
  doSaveDynamicField,
  doSingleCalcForTableCell,
  doUpdateDynamicField,
  updateTableCellByRowAndColumn,
})(OrdersTable);
